import { PageNotFoundWidget } from '@/widgets';
import { NextPageWithLayout } from '@/pages/_app';
import { Layout } from '@/ui';

const NotFoundPage: NextPageWithLayout = () => {
  return <PageNotFoundWidget />;
};

NotFoundPage.getLayout = (page) => <Layout>{page}</Layout>;

export default NotFoundPage;
